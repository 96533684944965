const host = window.location.hostname
const productionHost = 'web.declarant.by'
const development = 'http://10.128.8.142:7020/'
const isProduction = host === productionHost;
const production = `https://${productionHost}/`

export const TNVED = isProduction ? `${production}api/helper/` : `${development}helper/`
export const HELPER = TNVED
export const AUTH = isProduction ? `${production}api/auth/` : `${development}auth/`
export const NSI = isProduction ? `${production}api/updater/` : `${development}updater/`

export const SD = isProduction ? `${production}api/sd/` : `${development}sd/`
export const TD = isProduction ? `${production}api/td/` : `${development}td/`
export const SO = isProduction ? `${production}api/so/` : `${development}so/`
export const RS = isProduction ? `${production}api/us/` : `${development}us/`
export const EPI = isProduction ? `${production}api/epi/` : `${development}epi/`
export const PTD = isProduction ? `${production}api/ptd/` : `${development}ptd/`
export const DT = isProduction ? `${production}api/dt/` : `${development}dt/`

export const DTS = isProduction ? `${production}api/dts/` : `${development}dts/`

export const SEZ = isProduction ? `${production}api/sez/` : `${development}sez/`
export const MSG = isProduction ? `${production}api/msg/` : `${development}msg/`

export const UVR = isProduction ? `${production}api/uvr/` : `${development}uvr/`

export const PASS = UVR

export const REPORTS = isProduction ? `${production}api/reports/` : `${development}reports/`

export const EXCHANGER = isProduction ? `${production}api/eclient-exchanger/` : `${development}eclient-exchanger/`
export const NOTIFICATOR = isProduction ? `${production}api/notificator/` : `${development}notificator/`
export const SOCKET = isProduction ? `wss://web.declarant.by/api/notificator/websocket`
  : `ws://10.128.8.142:7020/notificator/websocket`

export const CATALOGS = isProduction ?
  `${production}api/helper/api/read/` :
  `${development}helper/api/read/`;


export const DOCUMENTS = {
  TD, SD, SO, RS, EPI, PTD, SEZ, DT, UVR, PASS, DTS
}